'use strict';

var FAQS = {
    init: function(){
        $('body').on('click', '[data-faq]', function(){
            $(this).toggleClass('item--active');
            $(this).find('.item__answer').slideToggle();
        });
    }
}

var COLLAPSIBLE_CONTENT = {
    init: function() {
        $('body').on('click', '[data-collapsible]', function(){
           $(this).toggleClass('item--active');
           $(this).find('.item__text').slideToggle();
        });
    }
}

var MODAL = {
    init: function() {
        this.setUpEvents();
    },
    setUpEvents: function() {
        var that = this;

        $('body').on('click', '[data-modal-trigger]', function(){
            var $modalContent = $($(this).data('modal-trigger'));

            if ($modalContent.length) {
                // update modal content
                $('#modal').find('.modal__content__inner').html($modalContent.html());

                that.showModal();
            }
        });

        $('body').on('click', '[data-close-modal]', function(){
            that.hideModal();
        });
    },
    showModal: function() {
        $('.blurContainer').addClass('active');
        $('body').addClass('locked');

        $('#modal').fadeIn(250, function(){
            $(this).addClass('visible');

            var videoEl = $(this).find('video')[0];

            if (videoEl) {
                videoEl.play();
            }
        });
    },
    hideModal: function() {
        var $modal = $('#modal');

        $modal.removeClass('visible');
        $modal.fadeOut(function(){
            // purge modal content
            $(this).find('.modal__content__inner').html('');
        });

        $('body').removeClass('locked');
        $('.blurContainer').removeClass('active');
    }
};

var MOBILE_NAV = {
    init: function() {
        $('body').on('click touchStart', '[data-mobile-menu-toggle]', function(){
            var $mobileNav = $('.mobileNav');

            $mobileNav.toggleClass('mobileNav--active');
        });
    }
}

var IN_PAGE_NAVIGATION = {
    init: function() {
        $('a[href*="#"]').on('click', function(e) {
            e.preventDefault()

            $('html, body').animate({
                scrollTop: $($(this).attr('href')).offset().top,
            }, 500, 'linear'
            )}
        );
    }
}
$(function(){
    FAQS.init();
    COLLAPSIBLE_CONTENT.init();
    MODAL.init();
    MOBILE_NAV.init();
    IN_PAGE_NAVIGATION.init();
});
